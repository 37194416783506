import React, { useContext, useRef, useEffect } from "react";
// import { TweenLite, Power3 } from "gsap";
import { GlobalStateContext } from "../context/GlobalContextProvider";

import Seo from "../components/seo/seo";
import Layout from "../components/layout/layout";
import PageBanner from "../components/common/PageBanner";

const CookiePolicy = () => {
  const state = useContext(GlobalStateContext);
  let container = useRef(null);

  useEffect(() => {
    if (!state.showPageLoading) {
      const cn = container.current;
      setTimeout(() => {
        cn.classList.remove("hide-container");
      }, state.containerAnimationDelay * 1000);
    }
  }, [state.showPageLoading, state.containerAnimationDelay]);

  return (
    <Layout>
      <Seo
        title='Cookie Policy | Virtual Reality Safety Training'
        description='Cookie Policy Virtual Reality Safety Training'
      />

      <PageBanner title='Cookie Policy' />
      <section
        className='main-container coookie-policy hide-container'
        ref={container}
      >
        <div className='row top-pad bottom-pad fd-col'>
          <div className='column fd-col'>
            <p className='no-mar'>
              With this cookie policy, we wish to inform you about the use of
              cookies or similar storage technologies (hereinafter “cookies”) on
              this website.
            </p>
            <h2 className='main-subtitle primary-color large'>
              What are Cookies?
            </h2>
            <p>
              Cookies are small text files that are stored by your browser on
              your device to save certain information or image files, such as
              pixels. The next time you visit our website on the same device,
              the information saved in the cookies will subsequently be
              transmitted either to our website (“First Party Cookie”) or to
              another website to which the cookie belongs (“Third Party
              Cookie”).
            </p>
            <p>
              Through the information saved and returned, the respective website
              recognizes that you have already accessed and visited it with the
              browser you use on that device. We use this information to be able
              to design and display the website in an optimum way in line with
              your preferences.
            </p>
            <p>
              In that respect, only the cookie itself is identified on your
              device. Beyond this extent, your personal data will only be saved
              upon your express consent or if it is strictly necessary to be
              able to use the service offered to and accessed by you
              accordingly.
            </p>
            <h2 className='main-subtitle primary-color large'>
              Consent to the use of Cookies
            </h2>
            <p>
              Cookies that are not strictly necessary to make the services
              available on our website are only used when you provide us with
              your consent. As soon as you actively accept the use of cookies
              via the cookie banner on our website, you consent to the use of
              cookies. You can, of course, customize your cookie settings at any
              time, for example by activating or deactivating individual cookie
              categories.
            </p>
            <p>
              You can find out how to deactivate or delete cookies in general
              (i.e. including the essential cookies) in your browser can be
              found under the last point of this Cookie Policy.
            </p>

            <h2 className='main-subtitle primary-color large'>Legal Basis</h2>
            <p>
              Where you have provided consent to the use of cookies on the basis
              of a notice (Cookie banner) displayed on our websites (“cookies
              banner”), the legality of this data processing is governed by Art.
              6 (1) (a) GDPR. Where consent is not requested, the legal basis
              for the use of these functional cookies is a legitimate interest
              (i.e. interest in the analysis, optimization and economic
              operation of the website and services) within the meaning of Art.
              6 (1) (f) GDPR).
            </p>
            <h2 className='main-subtitle primary-color large'>
              Cookie Categories
            </h2>
            <p>
              We divide the cookies we use into the following categories based
              on their purpose and function:
            </p>
            <ul>
              <li>Strictly Necessary Cookies;</li>
              <li>Functional Cookies;</li>
              <li>Performance Cookies; as well as</li>
              <li>Marketing / Third Party / Consent Based Cookies</li>
            </ul>

            <h2 className='main-subtitle primary-color large'>
              Strictly Necessary Cookies
            </h2>
            <p>
              Strictly necessary cookies guarantee functions without which you
              cannot use this web page and service as intended. These cookies
              are used exclusively by this website and are therefore first party
              cookies. This means that all information stored in the cookies
              will be returned to this website.
            </p>
            <p>
              The following strictly necessary cookies are used on our website:
            </p>

            <p>
              Strictly necessary cookies cannot be activated or deactivated
              individually. However, you can adjust your cookie preferences via
              the Cookie Settings or deactivate all cookies in your browser at
              any time (see below ”Deactivation or deletion of all cookies”).
            </p>

            <h2 className='main-subtitle primary-color large'>
              Functional Cookies
            </h2>
            <p>
              Functional cookies enable our website to store information already
              provided (such as registered name or language selection) and to
              offer you improved and more personalized functions based on this
              information. These cookies collect and store only anonymous
              information.
            </p>
            <h2 className='main-subtitle primary-color large'>
              Performance Cookies
            </h2>
            <p>
              Performance cookies collect information about how our websites are
              used in order to improve their attractiveness, content and
              functionality. These cookies help us, for example, to determine
              whether and which subpages of our website are visited and in which
              content you are particularly interested. In particular, we record
              the number of visits to a page, the number of subpages accessed,
              the time spent on our website, the order of the pages visited,
              which search terms led you to us, the country, region and, if
              applicable, the city from which access has been made, and the
              proportion of mobile devices accessing our websites. The IP
              address of your computer, that is transmitted for technical
              reasons, is also automatically anonymized and does not allow us to
              draw any conclusions about you, as an individual user.
            </p>
            <p>
              You also have the right to object to the processing of personal
              data that is used for statistical purposes in accordance with Art.
              89 para. 1 GDPR for reasons that arise from your particular
              situation. In order to exercise the aforementioned and other data
              subject rights, the affected party may, at any time, contact the
              site owner via the contact address provided in the imprint or
              privacy policy.
            </p>

            <h2 className='main-subtitle primary-color large'>
              Marketing / Third Party Cookies
            </h2>
            <p>
              Marketing / Third Party Cookies originate from external
              advertising companies (among others) and are used to gather
              information about the websites visited by you, in order to e.g.
              create targeted advertising for you.
            </p>

            <h2 className='main-subtitle primary-color large'>
              Deactivation or deletion of all cookies
            </h2>
            <p>
              You can set your web browser in such a way that cookies are
              generally prevented from being saved to your device and/or that
              you are asked each time whether you are in agreement with cookies
              being enabled. You can also at any time delete cookies that have
              been enabled again. You can find out how all this works in detail
              via your browser’s help function.
            </p>
            <p>
              Please note that generally deactivating cookies may lead to
              functional restrictions on our website.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default CookiePolicy;
